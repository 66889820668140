import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || "/"}`,
      }

      console.log(seo.title || defaultTitle)

      return (
        <div>
          <Helmet title={`${title} | `} titleTemplate={titleTemplate}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />

            <script
              type="text/javascript"
              src="//cdn.iubenda.com/cs/tcf/stub.js"
            ></script>
            <script type="text/javascript">
              {`var _iub = _iub || []; 
              (_iub.csConfiguration = {
                  lang: "en",
                  siteId: 1768979,
                  enableCMP: true,
                  googleAdsPreferenceManagement: true,
                  cookiePolicyId: 66084338,
                  banner: {
                    acceptButtonDisplay: true,
                    customizeButtonDisplay: true,
                    position: "float-top-center",
                    acceptButtonColor: "#f53d20",
                    acceptButtonCaptionColor: "white",
                    customizeButtonColor: "#322c7d",
                    customizeButtonCaptionColor: "white",
                    textColor: "white",
                    backgroundColor: "#161335",
                  },
                })`}
            </script>

            <script
              type="text/javascript"
              src="//cdn.iubenda.com/cs/iubenda_cs.js"
              charset="UTF-8"
              async
            ></script>

            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && (
              <meta property="og:type" content="article" />
            )}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            <link
              rel="stylesheet"
              href="https://use.typekit.net/ywz7rfd.css"
            ></link>
          </Helmet>
        </div>
      )
    }}
  />
)

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`
