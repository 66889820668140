import React, { Component } from "react"
import "./scss/menu.scss"
import { Link } from "gatsby"
import Chev from "../images/icons/chev-nav.png"

let countChev = 0
let countNav = 0
class Menu extends Component {
  navClick() {
    countNav += 1
    const navIcon = document.querySelector(".ham")
    navIcon.classList.toggle("active")
    let navList = document.querySelector(".navList")

    switch (countNav) {
      case 1:
        navList.style.transform = "scaleX(1)"
        break
      case 2:
        navList.style.transform = "scaleX(0)"
        countNav = 0
        break
      default:
        navList.style.transform = "scaleX(0)"
        countNav = 0
    }
  }

  chevClick() {
    let chev = document.querySelector("#chev")
    let sub = document.querySelector(".sub")
    countChev += 1
    switch (countChev) {
      case 1:
        chev.style.transform = "rotate(180deg"
        sub.style.display = "block"
        break
      case 2:
        chev.style.transform = "rotate(0deg)"
        sub.style.display = "none"
        countChev = 0
        break
      default:
        chev.style.transform = "rotate(0deg)"
        sub.style.display = "none"
        countChev = 0
    }
  }

  componentDidMount() {
    countNav = 0
    countChev = 0
  }

  render() {
    return (
      <>
        <div className="navIcon">
          <svg
            className="ham hamRotate ham1"
            viewBox="0 0 100 100"
            width="40"
            onClick={() => this.navClick()}
          >
            <path
              className="line top"
              d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
            />
            <path className="line middle" d="m 30,50 h 40" />
            <path
              className="line bottom"
              d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
            />
          </svg>
        </div>
        <nav className="navList">
          <ul>
            <li className="subLi">
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/" onClick={() => this.chevClick()}>
                Properties
              </Link>
              <div
                id="chev"
                className="chevNavOne"
                touch-action="none"
                onClick={() => this.chevClick()}
              >
                <img src={Chev} alt="chevron icon for activating sub menu" />
              </div>
            </li>
          </ul>
          <ul className="sub">
            <li className="subLi">
              <Link to="/dorlands-house">
                <h5>Dorlands House</h5>

                <p>Seeps 17</p>
              </Link>
            </li>
            <li className="subLi">
              <Link to="/dorlands-country-house">
                <h5>Country House &amp; Franky's Hideout</h5>

                <p>Sleeps 16</p>
              </Link>
            </li>
            <li className="subLi">
              <Link to="/frankys-hide-out">
                <h5>Franky's Hideout</h5>
                <p>Sleeps 2</p>
              </Link>
            </li>
          </ul>
          <ul>
            {/* <li>
              <Link to="/weddings">Weddings</Link>
            </li> */}
            <li>
              <Link to="/events">Events</Link>
            </li>
            <li>
              <Link to="/to-do">To Do</Link>
            </li>
            <li>
              <Link to="/info">Info</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact</Link>
            </li>

            <li>
              <Link to="/blog">Blog</Link>
            </li>
          </ul>
        </nav>
      </>
    )
  }
}

export default Menu
