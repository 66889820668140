import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import "../styles/styles.module.scss"
import "../styles/properties.scss"
import Psuedo from "./Psuedo"
import Scroller from "./Scroller"

const Layout = props => {
  return (
    <div className="globalContainer" id="header">
      <Header />
      <Psuedo />
      {props.children}
      <Scroller />
      <Footer />
    </div>
  )
}

export default Layout
