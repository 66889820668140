import React, { Component } from "react"
import "./scss/scroller.scss"
// eslint-disable-next-line
import { Link, animateScroll as scroll } from "react-scroll"
import chev from "../images/icons/chev-nav.png"

class Scroller extends Component {
  render() {
    return (
      <div className="scroller">
        <Link
          activeClass="active"
          to="header"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
        >
          <img src={chev} alt="up arrow to scroll page" />
        </Link>
      </div>
    )
  }
}

export default Scroller
