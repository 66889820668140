import React, { Component } from "react"
import { Link } from "gatsby"
import "./scss/header.scss"
import facebook from "../images/social/facebook.png"
import instagram from "../images/social/instagram.png"
import Menu from "./Menu"
import Logo from "../images/logo/dorlands_tree_logo.png"

class Header extends Component {
  navClick() {
    let navList = document.querySelector(".deskSub")
    navList.style.top = "31px"
  }

  navyClick() {
    let navList = document.querySelector(".deskSub")
    navList.style.top = "-430px"
  }

  render() {
    return (
      <header className="mainHeader">
        <div className="headerWrap">
          <Menu />
          <div className="headerTitleWrap">
            <Link to="/">
              <div className="headerLogo">
                <img
                  src={Logo}
                  alt="Dorlands Logo silhouette a oak tree with a swing"
                />
              </div>
              <div className="headerTitle">
                <h1>Dorlands</h1>
              </div>
            </Link>
          </div>
          <nav className="desktopNav">
            <ul>
              <li>
                <Link
                  to="/"
                  activeStyle={{
                    background: "#5b58c5",
                  }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link onMouseOver={() => this.navClick()}>Sleeps</Link>
              </li>
              <ul className="deskSub" onMouseLeave={() => this.navyClick()}>
                <li className="subLi">
                  <Link
                    to="/dorlands-house"
                    activeStyle={{
                      background: "#5b58c5",
                    }}
                  >
                    <h5>Dorlands House</h5>
                    <p>Sleeps 17</p>
                  </Link>
                </li>
                <li className="subLi">
                  <Link
                    to="/dorlands-country-house"
                    activeStyle={{
                      background: "#5b58c5",
                    }}
                  >
                    <h5>Country House &amp; Franky's Hideout</h5>
                    <p>Sleeps 16</p>
                  </Link>
                </li>

                <li className="subLi">
                  <Link
                    to="/frankys-hide-out"
                    activeStyle={{
                      background: "#5b58c5",
                    }}
                  >
                    <h5>Franky's Hideout</h5>
                    <p>Sleeps 2</p>
                  </Link>
                </li>
              </ul>
              {/* <li>
                <Link
                  to="/weddings"
                  activeStyle={{
                    background: "#5b58c5",
                  }}
                >
                  Weddings
                </Link>
              </li> */}
              <li>
                <Link
                  to="/events"
                  activeStyle={{
                    background: "#5b58c5",
                  }}
                >
                  Events
                </Link>
              </li>
              <li>
                <Link
                  to="/to-do"
                  activeStyle={{
                    background: "#5b58c5",
                  }}
                >
                  To Do
                </Link>
              </li>
              <li>
                <Link
                  to="/info"
                  activeStyle={{
                    background: "#5b58c5",
                  }}
                >
                  Info
                </Link>
              </li>
              <li>
                <Link
                  to="/contact-us"
                  activeStyle={{
                    background: "#5b58c5",
                    // borderRadius: "5px",
                  }}
                >
                  Contact
                </Link>
              </li>

              <li>
                <Link
                  to="/blog"
                  activeStyle={{
                    background: "#5b58c5",
                    // borderRadius: "5px",
                  }}
                >
                  Blog
                </Link>
              </li>
            </ul>
          </nav>

          <div className="social">
            <a
              href="https://www.facebook.com/Dorlands-118005288759269/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="facebook logo" />
            </a>
            <a
              href="https://www.instagram.com/dorlands.co.uk/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="instagram logo" />
            </a>
          </div>
        </div>

        <nav className="navHouse">
          <ul className="subUlOne">
            <li className="subLi">
              <Link className="aOne" to="/dorlandsHouse">
                <span className="property">Dorlands House</span>
                <span className="sleeps">Sleeps 17</span>
              </Link>
            </li>
            <li className="subLi">
              <Link className="aTwo" to="/dorlandsCountryHouse">
                <span className="property">Country House</span>
                <span className="sleeps">Sleeps 16</span>
              </Link>
            </li>
            <li className="subLi">
              <Link className="aThree" to="/frankysHideOut">
                <span className="property">Franky's Hideout</span>
                <span className="sleeps">Sleeps 2</span>
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    )
  }
}

export default Header
