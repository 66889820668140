import React from "react"
import { Link } from "gatsby"
import footerStyles from "./scss/footer.module.scss"
import facebook from "../images/social/facebook.png"
import instagram from "../images/social/instagram.png"
import logo from "../images/logo/dorlands_tree_logo.png"
import welsh from "../images/tourism/welshlogo.png"

const Footer = () => {
  return (
    <footer>
      <div className={footerStyles.footerMain}>
        <div className={footerStyles.welshLogo}>
          <img src={welsh} alt="welsh tourism logo 4 star tourism award" />
        </div>

        <div className={footerStyles.footerLogo}>
          <Link to="/">
            <h5>Dorlands</h5>
            <img
              src={logo}
              alt="Dorlands Logo silhouette a oak tree with a swing"
            />
          </Link>
          <div className={footerStyles.contact}>
            <Link to="/contact-us">Contact</Link>
          </div>
          <div className={footerStyles.devWrap}>
            <a
              href="https://www.netfrontier.co.uk/"
              className={footerStyles.dev}
              target="_blank"
            >
              Built By Net Frontier
            </a>
          </div>
        </div>

        <div className={footerStyles.socialFooter}>
          <a
            href="https://www.facebook.com/Dorlands-118005288759269/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="facebook logo" />
          </a>
          <a
            href="https://www.instagram.com/dorlands.co.uk/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="instagram logo" />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
